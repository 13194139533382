.footer {
  background: #1B253D; }

.footer-holder {
  display: flex;
  align-items: flex-start; }
  @media only screen and (max-width: 1058px) {
    .footer-holder {
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 24px;
      padding-bottom: 24px; } }
  @media only screen and (min-width: 1059px) {
    .footer-holder {
      justify-content: space-between;
      padding-top: 56px;
      padding-bottom: 56px; } }

.footer-logo-icon {
  display: block;
  width: 148px;
  height: 43px; }

@media only screen and (max-width: 1058px) {
  .footer-nav-list {
    margin-top: 16px; } }

@media only screen and (min-width: 1059px) {
  .footer-nav-list {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(4, auto);
    grid-gap: 16px 47px; } }

@media only screen and (max-width: 1058px) {
  .footer-nav-item:not(:first-child) {
    margin-top: 8px; } }

.footer-nav-link {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #F6FAFF;
  transition: color .5s ease; }
  .footer-nav-link:hover {
    color: #F0C602; }

.footer-label {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.002em;
  color: #7D88A2; }
  @media only screen and (max-width: 1058px) {
    .footer-label {
      margin-top: 16px; } }
  @media only screen and (min-width: 1059px) {
    .footer-label {
      margin-top: 21px; } }

.footer-contact-link {
  display: inline-flex;
  align-items: center;
  fill: #F6FAFF;
  color: #F6FAFF; }
  .footer-contact-link:hover {
    fill: #F0C602;
    color: #F0C602; }

.footer-mail-icon {
  display: block;
  width: 24px;
  height: 24px;
  transition: fill .5s ease; }

.footer-contact-email {
  margin-left: 8px;
  font-size: 16px;
  line-height: 24px;
  transition: color .5s ease; }

.footer-copyright {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.002em;
  color: #CBCED6; }
  @media only screen and (max-width: 1058px) {
    .footer-copyright {
      margin-top: 16px; } }
  @media only screen and (min-width: 1059px) {
    .footer-copyright {
      margin-top: 20px; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
