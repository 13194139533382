.thank-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px;
  background: #FFF;
  border-radius: 12px; }
  @media only screen and (max-width: 1058px) {
    .thank-modal {
      min-width: 375px;
      max-width: 432px; } }
  @media only screen and (min-width: 1059px) {
    .thank-modal {
      width: 432px; } }

.thank-title {
  font: "Rubik", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #1B253D;
  margin-bottom: 24px; }

.thank-message {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1B253D; }

.thank-button {
  display: inline-flex;
  padding: 0 32px;
  margin: 44px 0 76px; }

.thank-icon {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  vertical-align: -6px; }

.thank-img {
  display: block;
  width: 212px;
  height: 276px; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
