@media only screen and (min-width: 1059px) {
  .about-page .about-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; } }

.about-page .about-img {
  display: block; }
  @media only screen and (max-width: 1058px) {
    .about-page .about-img {
      width: 359px;
      height: 346px;
      margin-right: -16px; } }
  @media only screen and (min-width: 1059px) {
    .about-page .about-img {
      width: 518px;
      height: 472px;
      margin-left: -22px; } }

@media only screen and (min-width: 1059px) {
  .about-page .about-column {
    width: 520px; } }

.about-page .about-title {
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  color: #1B253D; }
  @media only screen and (max-width: 1058px) {
    .about-page .about-title {
      font-size: 48px;
      line-height: 64px;
      text-align: center;
      margin-top: 46px; } }

.about-page .about-subtitle {
  color: #1B253D;
  font-size: 24px;
  line-height: 32px;
  margin-top: 24px; }
  @media only screen and (max-width: 1058px) {
    .about-page .about-subtitle {
      text-align: center; } }

@media only screen and (max-width: 1058px) {
  .about-page .about-list {
    text-align: center;
    margin-top: 44px; } }

@media only screen and (min-width: 1059px) {
  .about-page .about-list {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 56px;
    margin-top: 56px; } }

@media only screen and (max-width: 1058px) {
  .about-page .about-item:not(:first-child) {
    margin-top: 56px; } }

.about-page .about-item-title {
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  color: #1B253D;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 12px; }

.about-page .about-item-message {
  color: #1B253D;
  font-size: 16px;
  line-height: 24px; }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
