.join-page .join-title {
  text-align: center; }

.join-page .join-msg {
  font-weight: 400;
  color: #1B253D;
  text-align: center; }
  @media only screen and (max-width: 1058px) {
    .join-page .join-msg {
      font-size: 16px;
      line-height: 24px; } }
  @media only screen and (min-width: 1059px) {
    .join-page .join-msg {
      font-size: 24px;
      line-height: 32px; } }
  .join-page .join-msg:first-child {
    margin-top: 16px; }
  .join-page .join-msg:not(:first-child) {
    margin-top: 8px; }

.join-page .join-subtitle {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  text-align: center;
  color: #1B253D; }
  @media only screen and (max-width: 1058px) {
    .join-page .join-subtitle {
      font-size: 24px;
      line-height: 32px;
      margin-top: 32px; } }
  @media only screen and (min-width: 1059px) {
    .join-page .join-subtitle {
      font-size: 32px;
      line-height: 40px;
      margin-top: 40px; } }

@media only screen and (max-width: 1058px) {
  .join-page .join-block {
    display: flex;
    flex-direction: column;
    margin-top: 24px; } }

@media only screen and (min-width: 1059px) {
  .join-page .join-block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 56px;
    grid-row-gap: 38.5px;
    margin-top: 32px; } }

.join-page .join-item, .join-page .join-form {
  background: #FFF;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px; }

.join-page .join-item {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*
    @include respond-to(wide-screens) {
      &.join-item1 {
        grid-area: 1 / 1 / 2 / 2;
      }
      &.join-item2 {
        grid-area: 2 / 1 / 3 / 2;
      }
      &.join-item3 {
        grid-area: 3 / 1 / 4 / 2;
      }
    }
    */ }
  @media only screen and (max-width: 1058px) {
    .join-page .join-item:not(:first-child) {
      margin-top: 24px; } }

.join-page .join-item-img {
  display: block;
  width: 126px;
  height: 105px; }

.join-page .join-item-msg {
  color: #1B253D; }
  @media only screen and (max-width: 1058px) {
    .join-page .join-item-msg {
      font-size: 16px;
      line-height: 24px;
      margin-left: 12px; } }
  @media only screen and (min-width: 1059px) {
    .join-page .join-item-msg {
      font-size: 20px;
      line-height: 28px;
      margin-left: 16px; } }

@media only screen and (max-width: 1058px) {
  .join-page .join-form {
    padding: 32px 16px;
    margin-top: 24px; } }

@media only screen and (min-width: 1059px) {
  .join-page .join-form {
    padding: 32px;
    grid-area: 1 / 2 / 4 / 3; } }

.join-page .join-form .join-form-title {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center; }

.join-page .join-form .form-item {
  margin-top: 24px; }

@media only screen and (max-width: 1058px) {
  .join-page .join-form .join-submit-button {
    width: 100%;
    margin-top: 44px; } }

@media only screen and (min-width: 1059px) {
  .join-page .join-form .join-submit-button {
    width: 272px;
    margin: 32px auto 0; } }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
