.report-holder .content-title {
  text-align: center; }

.report-holder .report-msg {
  text-align: center;
  color: #1B253D;
  margin-top: 16px; }
  @media only screen and (max-width: 1058px) {
    .report-holder .report-msg {
      font-size: 16px;
      line-height: 24px; } }
  @media only screen and (min-width: 1059px) {
    .report-holder .report-msg {
      font-size: 24px;
      line-height: 32px; } }

.report-form-block {
  background: #FFF;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px; }
  @media only screen and (max-width: 1058px) {
    .report-form-block {
      margin-top: 24px;
      padding: 24px 16px; } }
  @media only screen and (min-width: 1059px) {
    .report-form-block {
      width: 708px;
      margin: 44px auto 0;
      padding: 44px; } }

.report-form-title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #1B253D; }
  @media only screen and (max-width: 1058px) {
    .report-form-title {
      font-size: 20px;
      line-height: 28px; } }
  @media only screen and (min-width: 1059px) {
    .report-form-title {
      font-size: 32px;
      line-height: 40px; } }

@media only screen and (max-width: 1058px) {
  .radio-list {
    margin-top: 16px; } }

@media only screen and (min-width: 1059px) {
  .radio-list {
    margin-top: 32px; } }

@media only screen and (max-width: 1058px) {
  .radio-list .radio-list-item:not(:first-child) {
    margin-top: 8px; } }

@media only screen and (min-width: 1059px) {
  .radio-list .radio-list-item:not(:first-child) {
    margin-top: 20px; } }

.radio-list .radio-input {
  display: none; }
  .radio-list .radio-input:checked ~ .radio-label {
    background: #F6FAFF;
    border-color: #F0C602; }
    .radio-list .radio-input:checked ~ .radio-label .radio-icon-default {
      opacity: 0; }
    .radio-list .radio-input:checked ~ .radio-label .radio-icon-checked {
      opacity: 1; }
  .radio-list .radio-input:not(:checked) ~ .radio-label {
    cursor: pointer; }
    .radio-list .radio-input:not(:checked) ~ .radio-label .radio-icon-default {
      opacity: 1; }
    .radio-list .radio-input:not(:checked) ~ .radio-label .radio-icon-checked {
      opacity: 0; }
    .radio-list .radio-input:not(:checked) ~ .radio-label:hover {
      background-color: #F6FAFF; }

.radio-list .radio-label {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  transition: all .3s ease;
  border: 1px solid transparent; }
  @media only screen and (max-width: 1058px) {
    .radio-list .radio-label {
      padding: 8px 8px 8px 40px; } }
  @media only screen and (min-width: 1059px) {
    .radio-list .radio-label {
      padding: 16px 16px 16px 52px; } }

.radio-list .radio-icon {
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  transition: opacity .3s ease; }
  @media only screen and (max-width: 1058px) {
    .radio-list .radio-icon {
      top: 8px;
      left: 8px; } }
  @media only screen and (min-width: 1059px) {
    .radio-list .radio-icon {
      top: 20px;
      left: 16px; } }

.radio-list .radio-label-msg {
  font-weight: bold;
  color: #1B253D; }
  @media only screen and (max-width: 1058px) {
    .radio-list .radio-label-msg {
      font-size: 16px;
      line-height: 24px; } }
  @media only screen and (min-width: 1059px) {
    .radio-list .radio-label-msg {
      font-size: 24px;
      line-height: 32px; } }

@media only screen and (max-width: 1058px) {
  .report-submit-button {
    width: 100%;
    margin-top: 32px; } }

@media only screen and (min-width: 1059px) {
  .report-submit-button {
    width: 272px;
    margin: 32px auto; } }

/*
    @include respond-to(handhelds) {
    }
    @include respond-to(wide-screens) {
    }
*/
