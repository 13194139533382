@media only screen and (max-width: 1058px) {
  .file-upload-btn {
    width: 311px; } }

@media only screen and (min-width: 1059px) {
  .file-upload-btn {
    width: 272px; } }

.file-upload-btn input {
  display: none; }

.file-upload-header {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 1058px) {
    .file-upload-header {
      height: 107px; } }
  @media only screen and (min-width: 1059px) {
    .file-upload-header {
      height: 119px; } }
  .file-upload-header .logo-icon {
    display: block;
    width: 148px;
    height: 43px; }

.file-upload-page {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #F6FAFF;
  align-items: center;
  /*
  .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    letter-spacing: 0.17px;
    color: #023135;
  }
  */ }
  .file-upload-page .file-info-line {
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #1B253D;
    text-align: center; }
    @media only screen and (max-width: 1058px) {
      .file-upload-page .file-info-line {
        margin: 32px 0; } }
    @media only screen and (min-width: 1059px) {
      .file-upload-page .file-info-line {
        margin-bottom: 56px; } }
  .file-upload-page .top-part {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%; }
    @media only screen and (max-width: 1058px) {
      .file-upload-page .top-part {
        padding: 24px 16px; } }
    @media only screen and (min-width: 1059px) {
      .file-upload-page .top-part {
        padding: 24px; } }
  .file-upload-page .file-count {
    font-size: 14px;
    line-height: 18px;
    color: #51586A; }
  .file-upload-page .upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 28px;
    background: rgba(240, 198, 2, 0.12);
    border: 1px dashed #1B253D;
    box-sizing: border-box;
    border-radius: 4px; }
    .file-upload-page .upload-btn input {
      display: none; }
  .file-upload-page .upload-btn-msg {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1B253D;
    margin-left: 12px; }
  .file-upload-page .upload-btn-icon {
    display: block;
    width: 24px;
    height: 24px; }
  .file-upload-page .middle-part {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%; }
  @media only screen and (max-width: 1058px) {
    .file-upload-page .bottom-part {
      padding: 12px 20px; } }
  @media only screen and (min-width: 1059px) {
    .file-upload-page .bottom-part {
      display: flex;
      justify-content: flex-end;
      padding: 8px 20px 20px; } }
  .file-upload-page .file-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px; }
    .file-upload-page .file-list-item.error {
      background-color: rgba(240, 198, 2, 0.1); }
  .file-upload-page .preview-icon {
    display: block;
    width: 30px;
    height: 40px; }
  .file-upload-page .file-info {
    flex: 1;
    min-width: 0;
    padding: 0 16px; }
  .file-upload-page .file-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    color: #000; }
  .file-upload-page .file-size {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.002em;
    color: #51586A;
    margin-top: 2px; }
  .file-upload-page .error-line {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.002em;
    color: #EE5D50;
    margin-top: 2px; }
  .file-upload-page .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .file-upload-page .extension {
    flex-shrink: 0; }
  .file-upload-page .file-delete-icon {
    display: block;
    width: 28px;
    height: 28px; }
  .file-upload-page .reload-icon {
    display: block;
    width: 24px;
    height: 24px; }
  .file-upload-page .description-block {
    padding: 24px; }
  .file-upload-page .description-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1B253D; }
  .file-upload-page .description-field {
    display: block;
    width: 100%;
    height: 96px;
    margin-top: 8px;
    border-radius: 4px;
    border: solid 1px #7D88A2;
    background-color: #fff;
    resize: none;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 24px;
    color: #000; }

.file-upload-wrapper {
  box-sizing: border-box;
  max-width: 516px;
  width: 100%;
  margin: 0 auto; }
  @media only screen and (max-width: 1058px) {
    .file-upload-wrapper {
      padding: 24px 16px 16px; } }
  @media only screen and (min-width: 1059px) {
    .file-upload-wrapper {
      padding: 32px 16px; } }

.file-frame {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #FFF;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px; }
  @media only screen and (max-width: 1058px) {
    .file-frame.file-frame1 {
      padding: 44px 16px 24px; } }
  @media only screen and (min-width: 1059px) {
    .file-frame.file-frame1 {
      padding: 44px; } }
  @media only screen and (max-width: 1058px) {
    .file-frame.file-frame2 {
      padding-bottom: 24px; } }
  @media only screen and (min-width: 1059px) {
    .file-frame.file-frame2 {
      padding-bottom: 44px; } }
  @media only screen and (max-width: 1058px) {
    .file-frame.file-frame3 {
      padding: 44px 16px; } }
  @media only screen and (min-width: 1059px) {
    .file-frame.file-frame3 {
      padding: 44px; } }
  .file-frame .file-img1, .file-frame .file-img2, .file-frame .file-img3, .file-frame .file-img4 {
    display: block;
    width: 212px;
    height: 276px; }
  .file-frame .frame-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #1B253D;
    margin-top: 68px; }
  .file-frame .file-info-line2 {
    font-size: 20px;
    line-height: 28px;
    color: #1B253D;
    text-align: center;
    margin: 64px 0 24px; }
  .file-frame .file-or-line {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1B253D;
    text-align: center;
    margin-bottom: 24px; }
  .file-frame .file-info-line3 {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #51586A; }
  .file-frame .file-info-line3_1 {
    margin: 24px 0; }
  .file-frame .file-info-line3_2 {
    margin: 12px 0 32px; }
  .file-frame .file-info-line3_3 {
    margin-top: 24px; }
  .file-frame .file-btn-block {
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 1058px) {
      .file-frame .file-btn-block .button {
        width: 149px; } }
    @media only screen and (min-width: 1059px) {
      .file-frame .file-btn-block .button {
        width: 130px; } }
    .file-frame .file-btn-block .button:not(:first-child) {
      margin-left: 12px; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
