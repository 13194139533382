@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Rubik:wght@400;700&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%; }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
 * ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
 * ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit; }
  a:active, a:hover {
    outline: 0; }

/* Text-level semantics
 * ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
 * ========================================================================== */
/**
 * Remove border when inside `a` element in IE 8/9/10.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
 * ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0;
  border: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
 * ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }
  button:focus,
  input:focus,
  optgroup:focus,
  select:focus,
  textarea:focus {
    outline: none; }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
  cursor: pointer;
  padding: 0;
  background-color: transparent; }
  button::-moz-focus-inner {
    border: 0; }

button, input, textarea {
  border: none; }

input {
  box-sizing: border-box; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
legend {
  border: 0;
  padding: 0; }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
 * ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

i, address {
  font-style: normal; }

ol, ul {
  list-style: none; }

::-ms-clear {
  display: none; }

* {
  word-break: break-word;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none; }
  *:focus {
    outline: 0 !important;
    outline-color: transparent !important;
    outline-width: 0 !important;
    outline-style: none !important;
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important; }

html, body {
  overflow-x: hidden; }
  @media only screen and (max-width: 1058px) {
    html, body {
      min-width: 375px; } }
  @media only screen and (min-width: 1059px) {
    html, body {
      min-width: 1024px; } }

body {
  font-family: "Karla", sans-serif;
  color: #1B253D; }

@media only screen and (min-width: 1059px) {
  .mobile {
    display: none !important; } }

@media only screen and (max-width: 1058px) {
  .desktop {
    display: none !important; } }

.link {
  text-decoration: underline; }

.title {
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  color: #1B253D; }
  @media only screen and (max-width: 1058px) {
    .title {
      font-size: 32px;
      line-height: 40px; } }
  @media only screen and (min-width: 1059px) {
    .title {
      font-size: 48px;
      line-height: 64px; } }

.button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background-color: #F0C602;
  border-radius: 12px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1B253D;
  transition: background .5s ease, opacity .5s ease; }
  .button:not(:disabled) {
    cursor: pointer; }
    .button:not(:disabled):hover {
      background-color: #F6B11E; }
    .button:not(:disabled):active {
      background-color: #F5A803; }
  .button:disabled {
    opacity: .5; }
  .button.button-tertiary {
    border: 1px solid #F0C602;
    background-color: transparent; }
    .button.button-tertiary:hover {
      background-color: #F0C602; }
  .button.button-ghost {
    background-color: transparent; }
    .button.button-ghost:hover {
      background-color: transparent; }

.page {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

.main {
  flex: 1; }

.strong {
  font-weight: bold; }

.error-tooltip {
  width: 335px;
  padding: 10px 16px 8px;
  font-family: "Karla", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  border-radius: 8px;
  text-align: center;
  position: relative; }
  .error-tooltip:after {
    content: '';
    display: block;
    bottom: -6px;
    left: 50%; }

.form-line:not(:first-child) {
  margin-top: 24px; }

@media only screen and (min-width: 1059px) {
  .form-line {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; } }

@media only screen and (max-width: 1058px) {
  .form-line .form-item:not(:first-child) {
    margin-top: 24px; } }

@media only screen and (min-width: 1059px) {
  .form-line .form-item:not(:first-child) {
    margin-left: 24px; } }

.form-item {
  width: 100%;
  position: relative; }

.form-label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #1B253D;
  margin-bottom: 8px; }

.form-input {
  display: block;
  width: 100%;
  height: 48px;
  background: #F6FAFF;
  border: 1px solid #7D88A2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 16px;
  font-size: 16px;
  line-height: 24px;
  color: #1B253D;
  transition: border .5s ease; }
  .form-input:focus {
    border-color: #F6B11E; }
  .form-input.error {
    border-color: #EE5D50; }

.form-item-error {
  position: absolute;
  bottom: -21px;
  left: 0;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.002em;
  color: #EE5D50; }

.form-textarea {
  display: block;
  width: 100%;
  height: 72px;
  background: #F6FAFF;
  border: 1px solid #7D88A2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 16px;
  line-height: 24px;
  color: #1B253D;
  transition: border .5s ease;
  resize: none; }
  .form-textarea:focus {
    border-color: #F6B11E; }
  .form-textarea.error {
    border-color: #EE5D50; }

.fixed {
  height: 100vh;
  overflow-y: hidden;
  padding-right: 15px; }

.overflow-x {
  overflow-x: hidden; }
