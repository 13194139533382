.textback__form {
  background: #FFF;
  box-shadow: 0 8px 12px rgba(255, 255, 255, 0.06);
  border-radius: 12px;
  position: relative; }
  @media only screen and (max-width: 1058px) {
    .textback__form {
      padding: 16px; } }
  @media only screen and (min-width: 1059px) {
    .textback__form {
      padding: 32px; } }
  @media only screen and (min-width: 1059px) {
    .textback__form .phone-form {
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  .textback__form .input-wrapper {
    display: flex;
    justify-content: center;
    align-items: stretch;
    border: 1px solid #7D88A1;
    border-radius: 4px;
    height: 48px;
    transition: border-color .3s ease; }
    @media only screen and (min-width: 1059px) {
      .textback__form .input-wrapper {
        width: 259px;
        margin-right: 20px; } }
  .textback__form .focus .input-wrapper {
    border-color: #1B253D; }
  .textback__form .error .input-wrapper {
    border-color: #EE5D50; }
  .textback__form .input-mask {
    flex: 1;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: "Karla", sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    color: #51586A; }
    @media only screen and (max-width: 1058px) {
      .textback__form .input-mask {
        padding: 0 0 0 20px; } }
    @media only screen and (min-width: 1059px) {
      .textback__form .input-mask {
        padding: 0 0 0 10px;
        width: 179px; } }
  @media only screen and (max-width: 1058px) {
    .textback__form .submit-button {
      width: 100%;
      margin-top: 16px; } }
  @media only screen and (min-width: 1059px) {
    .textback__form .submit-button {
      width: 164px; } }
  .textback__form .error-line {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.002em;
    color: #EE5D50; }
    @media only screen and (max-width: 1058px) {
      .textback__form .error-line {
        margin-top: 8px;
        text-align: left; } }
    @media only screen and (min-width: 1059px) {
      .textback__form .error-line {
        position: absolute;
        bottom: 6px;
        left: 32px; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
