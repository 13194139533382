.input-select__countries {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  padding-left: 12px; }
  .input-select__countries:after {
    content: '';
    display: block;
    transition: transform .2s ease;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #7D88A1;
    border-bottom: none;
    margin-left: 3px; }
  .input-select__countries .input-select-list {
    display: none;
    position: absolute;
    top: 56px;
    left: 0;
    z-index: 100;
    background: #FFF;
    border: 1px solid #7D88A2;
    border-radius: 4px;
    padding: 8px 0; }
  .input-select__countries .input-select-list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 32px;
    padding: 0 10px; }
    .input-select__countries .input-select-list-item:not(:first-child) {
      margin-top: 8px; }
  .input-select__countries .country-name {
    white-space: nowrap;
    font-size: 16px;
    line-height: 19px;
    color: #51586A;
    margin-left: 10px; }
  .input-select__countries .flag-icon {
    display: block;
    width: 24px;
    height: 24px; }
  .input-select__countries.input-select__open:after {
    transform: rotate(-180deg); }
  .input-select__countries.input-select__open .input-select-list {
    display: block; }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
