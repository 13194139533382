.drag-drop-zone-wrapper {
  width: 100%;
  position: relative; }
  @media only screen and (max-width: 1058px) {
    .drag-drop-zone-wrapper {
      display: flex;
      flex: 1 1; } }

.drag-drop-zone {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  border: 2px dashed #F0C602;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  color: #51586A;
  text-align: center; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
