.landing-hero {
  background: #F6FAFF; }
  @media only screen and (max-width: 1058px) {
    .landing-hero .landing-hero-bg {
      background: url("./img/hero/hero-bg-mob.jpg") no-repeat #1A2339;
      background-size: cover; } }
  @media only screen and (min-width: 1059px) {
    .landing-hero .landing-hero-bg {
      border-radius: 0 0 0 200px;
      background: url("./img/hero/hero-bg.jpg") no-repeat #1A2339;
      background-size: cover; } }
  .landing-hero .landing-hero-holder {
    display: flex; }
    @media only screen and (max-width: 1058px) {
      .landing-hero .landing-hero-holder {
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
        padding-top: 56px;
        padding-bottom: 56px; } }
    @media only screen and (min-width: 1059px) {
      .landing-hero .landing-hero-holder {
        justify-content: space-between;
        align-items: center;
        padding-top: 104px;
        padding-bottom: 104px; } }
  @media only screen and (min-width: 1059px) {
    .landing-hero .landing-hero-column {
      width: 507px; } }
  .landing-hero .landing-title {
    font-family: "Rubik", sans-serif;
    font-weight: bold;
    color: #FFF; }
    @media only screen and (max-width: 1058px) {
      .landing-hero .landing-title {
        font-size: 38px;
        line-height: 48px; } }
    @media only screen and (min-width: 1059px) {
      .landing-hero .landing-title {
        font-size: 62px;
        line-height: 84px; } }
  .landing-hero .landing-subtitle {
    font-family: "Rubik", sans-serif;
    color: #F6FAFF; }
    @media only screen and (max-width: 1058px) {
      .landing-hero .landing-subtitle {
        font-size: 16px;
        line-height: 26px;
        margin-top: 12px; } }
    @media only screen and (min-width: 1059px) {
      .landing-hero .landing-subtitle {
        font-size: 24px;
        line-height: 34px;
        margin-top: 16px; } }
  .landing-hero .landing-hero-img {
    display: block; }
    @media only screen and (max-width: 1058px) {
      .landing-hero .landing-hero-img {
        width: 343px;
        height: 361px; } }
    @media only screen and (min-width: 1059px) {
      .landing-hero .landing-hero-img {
        width: 492px;
        height: 519px; } }
  @media only screen and (max-width: 1058px) {
    .landing-hero .textback {
      margin-top: 32px;
      margin-bottom: 32px; } }
  @media only screen and (min-width: 1059px) {
    .landing-hero .textback {
      margin-top: 48px; } }
  .landing-hero .textback .agree {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.002em;
    color: #7D88A2;
    margin-top: 16px; }

.landing-hero-icon {
  width: 24px;
  height: 24px;
  margin-left: 4px; }
  @media only screen and (max-width: 1058px) {
    .landing-hero-icon {
      vertical-align: -6px; } }
  @media only screen and (min-width: 1059px) {
    .landing-hero-icon {
      vertical-align: -4px; } }

/* START .landing-hero2 */
@media only screen and (max-width: 1058px) {
  .landing-hero2 {
    background: url("./img/hero/hero2-bg-mob.jpg") no-repeat #1A2339;
    background-size: cover; } }

@media only screen and (min-width: 1059px) {
  .landing-hero2 {
    background: url("./img/hero/hero2-bg.jpg") no-repeat #1A2339;
    background-size: cover; } }

.landing-hero2 .hero2-title {
  color: #FFF;
  text-align: center; }

@media only screen and (min-width: 1059px) {
  .landing-hero2 .textback__form {
    width: 507px; } }

@media only screen and (max-width: 1058px) {
  .landing-hero2 .textback {
    margin-top: 24px; } }

@media only screen and (min-width: 1059px) {
  .landing-hero2 .textback {
    margin-top: 48px; } }

.landing-hero2 .textback .agree {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.002em;
  margin-top: 16px;
  text-align: center; }
  @media only screen and (max-width: 1058px) {
    .landing-hero2 .textback .agree {
      color: #7D88A2; } }
  @media only screen and (min-width: 1059px) {
    .landing-hero2 .textback .agree {
      color: #F6FAFF; } }

.landing-hero2 .hero2-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 1058px) {
    .landing-hero2 .hero2-holder {
      padding-top: 56px;
      padding-bottom: 56px; } }
  @media only screen and (min-width: 1059px) {
    .landing-hero2 .hero2-holder {
      padding-top: 104px;
      padding-bottom: 104px; } }

.landing-hero2 .hero2-subtitle {
  text-align: center; }
  @media only screen and (max-width: 1058px) {
    .landing-hero2 .hero2-subtitle {
      font-size: 16px;
      line-height: 24px;
      color: #FFF;
      margin-top: 16px; } }
  @media only screen and (min-width: 1059px) {
    .landing-hero2 .hero2-subtitle {
      font-size: 24px;
      line-height: 32px;
      color: #F6FAFF;
      margin-top: 24px; } }

.landing-hero2 .hero2-message {
  text-align: center;
  color: #F6FAFF; }
  @media only screen and (max-width: 1058px) {
    .landing-hero2 .hero2-message {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.002em; } }
  @media only screen and (min-width: 1059px) {
    .landing-hero2 .hero2-message {
      font-size: 16px;
      font-weight: bold;
      line-height: 24px; } }

.landing-hero2 .hr {
  display: block;
  height: 2px;
  background: #F6FAFF; }
  @media only screen and (max-width: 1058px) {
    .landing-hero2 .hr {
      width: 200px;
      margin: 24px 0; } }
  @media only screen and (min-width: 1059px) {
    .landing-hero2 .hr {
      width: 288px;
      margin: 44px 0; } }

/* END .landing-hero2 */
/* START .landing-hero3 */
.landing-hero3 {
  background: #1B253D; }
  @media only screen and (max-width: 1058px) {
    .landing-hero3 .hero3-holder {
      padding-top: 56px;
      padding-bottom: 56px; } }
  @media only screen and (min-width: 1059px) {
    .landing-hero3 .hero3-holder {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 104px;
      padding-bottom: 104px; } }
  .landing-hero3 .hero3-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media only screen and (min-width: 1059px) {
      .landing-hero3 .hero3-block {
        width: 507px; } }
  .landing-hero3 .hero3-title {
    color: #FFF;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px; }
  @media only screen and (max-width: 1058px) {
    .landing-hero3 .hero3-subtitle {
      font-size: 16px;
      line-height: 24px;
      color: #FFF;
      margin-top: 16px; } }
  @media only screen and (min-width: 1059px) {
    .landing-hero3 .hero3-subtitle {
      font-size: 24px;
      line-height: 32px;
      color: #F6FAFF;
      margin-top: 16px; } }
  .landing-hero3 .textback {
    width: 100%; }
    @media only screen and (max-width: 1058px) {
      .landing-hero3 .textback {
        margin-top: 24px; } }
    @media only screen and (min-width: 1059px) {
      .landing-hero3 .textback {
        margin-top: 32px; } }
    .landing-hero3 .textback .agree {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.002em;
      margin-top: 16px; }
      @media only screen and (max-width: 1058px) {
        .landing-hero3 .textback .agree {
          color: #7D88A2; } }
      @media only screen and (min-width: 1059px) {
        .landing-hero3 .textback .agree {
          color: #7D88A2; } }
  .landing-hero3 .hero3-logo-block {
    display: flex;
    margin: 44px auto 0;
    justify-content: center;
    align-items: center; }
  .landing-hero3 .logo-satisfaction {
    display: block;
    width: 123px;
    height: 68px; }
  .landing-hero3 .logo-guarantee {
    display: block;
    width: 100px;
    height: 92px;
    margin-left: 45px; }
  @media only screen and (max-width: 1058px) {
    .landing-hero3 .hero3-img {
      display: none; } }
  @media only screen and (min-width: 1059px) {
    .landing-hero3 .hero3-img {
      display: block;
      width: 525px;
      height: 444px; } }

/* END .landing-hero3 */
/* START .points-section */
.points-section {
  background: #F6FAFF; }

@media only screen and (max-width: 1058px) {
  .points-holder {
    padding-top: 56px;
    padding-bottom: 56px; } }

@media only screen and (min-width: 1059px) {
  .points-holder {
    padding-top: 104px;
    padding-bottom: 104px; } }

.points-title {
  text-align: center; }

.points-list {
  display: flex; }
  @media only screen and (max-width: 1058px) {
    .points-list {
      flex-direction: column;
      margin-top: 68px; } }
  @media only screen and (min-width: 1059px) {
    .points-list {
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 100px; } }

.points-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #1B253D;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 0 16px 20px; }
  @media only screen and (max-width: 1058px) {
    .points-item:not(:first-child) {
      margin-top: 68px; } }
  @media only screen and (min-width: 1059px) {
    .points-item {
      width: 332px; } }

.points-img {
  display: block;
  width: 77.88px;
  height: 76px;
  margin-top: -36px; }

.points-message {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #FFF;
  margin-top: 4px; }

/* END .points-section */
/* START .dashboard-section */
.dashboard-section {
  background: #FFF; }

.dashboard-holder {
  display: flex; }
  @media only screen and (max-width: 1058px) {
    .dashboard-holder {
      flex-direction: column;
      justify-content: center;
      padding-top: 56px;
      padding-bottom: 56px; } }
  @media only screen and (min-width: 1059px) {
    .dashboard-holder {
      justify-content: space-between;
      align-items: center;
      padding-top: 64px;
      padding-bottom: 64px; } }

.dashboard-title {
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  color: #1B253D; }
  @media only screen and (max-width: 1058px) {
    .dashboard-title {
      font-size: 24px;
      line-height: 32px;
      text-align: center; } }
  @media only screen and (min-width: 1059px) {
    .dashboard-title {
      font-size: 38px;
      line-height: 48px;
      width: 322px; } }

.dashboard-icons {
  display: block; }
  @media only screen and (max-width: 1058px) {
    .dashboard-icons {
      width: 337px;
      height: 392px;
      margin-top: 36px; } }
  @media only screen and (min-width: 1059px) {
    .dashboard-icons {
      width: 578px;
      height: 220px; } }

/* END .dashboard-section */
/* START .points-section */
.about-section {
  background-color: #F6FAFF; }

@media only screen and (max-width: 1058px) {
  .about-holder {
    padding-top: 56px;
    padding-bottom: 56px; } }

@media only screen and (min-width: 1059px) {
  .about-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 104px;
    padding-bottom: 104px; } }

.about-column {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media only screen and (max-width: 1058px) {
    .about-column {
      align-items: center; } }
  @media only screen and (min-width: 1059px) {
    .about-column {
      align-items: flex-start;
      width: 462px; } }

@media only screen and (max-width: 1058px) {
  .about-title {
    text-align: center; } }

.about-message {
  color: #1B253D; }
  @media only screen and (max-width: 1058px) {
    .about-message {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin-top: 24px; } }
  @media only screen and (min-width: 1059px) {
    .about-message {
      font-size: 24px;
      line-height: 32px;
      margin-top: 24px; } }

.about-link {
  display: inline-block;
  color: #007AFF; }
  @media only screen and (max-width: 1058px) {
    .about-link {
      font-size: 16px;
      line-height: 24px;
      margin: 12px 0 24px; } }
  @media only screen and (min-width: 1059px) {
    .about-link {
      font-size: 24px;
      line-height: 32px;
      margin-top: 32px; } }

.about-img {
  display: block; }
  @media only screen and (max-width: 1058px) {
    .about-img {
      width: 343px;
      height: 343px; } }
  @media only screen and (min-width: 1059px) {
    .about-img {
      width: 504px;
      height: 504px; } }

/* END .points-section */
/* START .why-section */
.why-section {
  background-color: #fff; }

@media only screen and (max-width: 1058px) {
  .why-holder {
    padding-top: 56px;
    padding-bottom: 56px; } }

@media only screen and (min-width: 1059px) {
  .why-holder {
    padding-top: 104px;
    padding-bottom: 104px; } }

.why-title {
  text-align: center; }

@media only screen and (max-width: 1058px) {
  .why-list {
    margin-top: 32px; } }

@media only screen and (min-width: 1059px) {
  .why-list {
    display: inline-grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 81px 32px;
    margin-top: 64px; } }

.why-item {
  display: flex; }
  @media only screen and (max-width: 1058px) {
    .why-item {
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .why-item:not(:first-child) {
        margin-top: 44px; } }
  @media only screen and (min-width: 1059px) {
    .why-item {
      justify-content: space-between;
      align-items: flex-start; } }

.why-img {
  display: block;
  border-radius: 0 32px 32px 32px;
  width: 96px;
  height: 96px; }

@media only screen and (min-width: 1059px) {
  .why-column {
    margin-left: 24px; } }

.why-item-title {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #1B253D; }
  @media only screen and (max-width: 1058px) {
    .why-item-title {
      text-align: center;
      margin-top: 24px; } }

.why-item-message {
  color: #1B253D; }
  @media only screen and (max-width: 1058px) {
    .why-item-message {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin-top: 8px; } }
  @media only screen and (min-width: 1059px) {
    .why-item-message {
      font-size: 24px;
      line-height: 32px;
      margin-top: 4px; } }

/* END .why-section */
/* START .tesimonials-section */
.testimonials-section {
  background: #F6FAFF; }

@media only screen and (max-width: 1058px) {
  .testimonials-holder {
    padding-top: 56px;
    padding-bottom: 32px; } }

@media only screen and (min-width: 1059px) {
  .testimonials-holder {
    padding-top: 104px;
    padding-bottom: 104px; } }

.testimonials-title {
  text-align: center; }

.testimonial-heart-icon {
  display: inline-block; }
  @media only screen and (max-width: 1058px) {
    .testimonial-heart-icon {
      width: 32px;
      height: 32px;
      margin-left: 4px;
      vertical-align: -4px; } }
  @media only screen and (min-width: 1059px) {
    .testimonial-heart-icon {
      width: 48px;
      height: 48px;
      margin-left: 8px;
      vertical-align: -6px; } }

/*  END .tesimonials-section */
/* START .savior-section */
.savior-section {
  background-color: #fff; }

@media only screen and (max-width: 1058px) {
  .savior-holder {
    padding-top: 56px;
    padding-bottom: 56px; } }

@media only screen and (min-width: 1059px) {
  .savior-holder {
    padding-top: 104px;
    padding-bottom: 104px; } }

.savior-title {
  text-align: center; }

.savior-subtitle {
  text-align: center;
  color: #1B253D; }
  @media only screen and (max-width: 1058px) {
    .savior-subtitle {
      font-size: 16px;
      line-height: 24px;
      margin-top: 16px; } }
  @media only screen and (min-width: 1059px) {
    .savior-subtitle {
      font-size: 24px;
      line-height: 32px;
      margin-top: 32px; } }

@media only screen and (max-width: 1058px) {
  .savior-list {
    margin-top: 32px; } }

@media only screen and (min-width: 1059px) {
  .savior-list {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 68px;
    margin-top: 64px; } }

.savior-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; }
  @media only screen and (max-width: 1058px) {
    .savior-item:not(:first-child) {
      margin-top: 44px; } }
  @media only screen and (min-width: 1059px) {
    .savior-item {
      width: 296px; } }

.savior-img {
  display: block;
  width: 127px;
  height: 210px; }

.savior-message {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #1B253D;
  margin-top: 16px; }

/* END .savior-section */
/* START .join-section */
@media only screen and (max-width: 1058px) {
  .join-section {
    background: url("./img/join-bg-mob.svg") no-repeat center #F6FAFF; } }

@media only screen and (min-width: 1059px) {
  .join-section {
    background: url("./img/join-bg.svg") no-repeat center #F6FAFF; } }

@media only screen and (max-width: 1058px) {
  .join-section .submit-app {
    width: 311px;
    margin-top: 24px; } }

@media only screen and (min-width: 1059px) {
  .join-section .submit-app {
    width: 213px;
    margin-top: 64px; } }

.join-section .join-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  @media only screen and (max-width: 1058px) {
    .join-section .join-holder {
      padding-top: 56px;
      padding-bottom: 56px; } }
  @media only screen and (min-width: 1059px) {
    .join-section .join-holder {
      padding-top: 104px;
      padding-bottom: 104px; } }

.join-section .join-title {
  text-align: center; }

.join-section .join-subtitle {
  text-align: center;
  color: #1B253D; }
  @media only screen and (max-width: 1058px) {
    .join-section .join-subtitle {
      font-size: 16px;
      line-height: 24px;
      margin-top: 16px; } }
  @media only screen and (min-width: 1059px) {
    .join-section .join-subtitle {
      font-size: 24px;
      line-height: 32px;
      margin-top: 24px; } }

.join-section .join-message {
  margin-top: 16px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.002em;
  text-align: center; }
  @media only screen and (max-width: 1058px) {
    .join-section .join-message {
      color: #7D88A2; } }
  @media only screen and (min-width: 1059px) {
    .join-section .join-message {
      color: #51586A; } }

/* END .join-section */
/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
