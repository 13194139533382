.content-page {
  background: #F6FAFF;
  font-size: 16px;
  line-height: 24px;
  color: #1B253D; }
  @media only screen and (max-width: 1058px) {
    .content-page {
      padding-top: 56px;
      padding-bottom: 56px; } }
  @media only screen and (min-width: 1059px) {
    .content-page {
      padding-top: 104px;
      padding-bottom: 104px; } }
  .content-page .mt8 {
    margin-top: 8px; }
  .content-page .mt12 {
    margin-top: 12px; }
  .content-page .mt16 {
    margin-top: 16px; }

.content-title {
  font-family: "Rubik", sans-serif;
  font-weight: bold; }
  @media only screen and (max-width: 1058px) {
    .content-title {
      font-size: 32px;
      line-height: 40px; } }
  @media only screen and (min-width: 1059px) {
    .content-title {
      font-size: 48px;
      line-height: 64px; } }

.content-h2 {
  font-family: "Rubik", sans-serif;
  font-weight: bold; }
  @media only screen and (max-width: 1058px) {
    .content-h2 {
      font-size: 24px;
      line-height: 32px;
      margin-top: 32px; } }
  @media only screen and (min-width: 1059px) {
    .content-h2 {
      font-size: 32px;
      line-height: 40px;
      margin-top: 44px; } }

.content-h3 {
  font-weight: bold; }
  @media only screen and (max-width: 1058px) {
    .content-h3 {
      font-size: 20px;
      line-height: 28px;
      margin-top: 16px; } }
  @media only screen and (min-width: 1059px) {
    .content-h3 {
      font-size: 20px;
      line-height: 28px;
      margin-top: 32px; } }

.content-p {
  margin-top: 32px; }

.content-p-subtitle {
  display: block;
  font-weight: bold;
  margin-bottom: 8px; }

.content-ul {
  margin-top: 8px; }
  .content-ul > li {
    padding-left: 30px;
    font-family: "Karla", sans-serif;
    position: relative; }
    .content-ul > li:before {
      content: "";
      background: #1B253D;
      display: block;
      position: absolute;
      top: 9px;
      left: 9px;
      width: 4px;
      height: 4px;
      border-radius: 50%; }
    .content-ul > li + li {
      margin-top: 6px; }

.content-table {
  width: 100%;
  border: none; }
  @media only screen and (max-width: 1058px) {
    .content-table {
      margin-top: 32px; } }
  @media only screen and (min-width: 1059px) {
    .content-table {
      margin-top: 44px; } }
  .content-table tr:last-child td {
    border-bottom: none;
    padding-bottom: 0; }
  .content-table th {
    text-align: left;
    background-color: #fff;
    font-weight: bold;
    padding: 8px 8px 12px 8px; }
    .content-table th:first-child {
      padding-left: 8px; }
      @media only screen and (max-width: 1058px) {
        .content-table th:first-child {
          width: 35%; } }
      @media only screen and (min-width: 1059px) {
        .content-table th:first-child {
          width: 26%; } }
    .content-table th:not(:first-child) {
      border-left: 1px solid #F6FAFF; }
  .content-table th, .content-table td {
    border-collapse: collapse; }
  .content-table td {
    padding: 12px 0 8px 12px;
    border: 1px solid #fff; }
    .content-table td:first-child {
      border-left: none;
      padding-left: 8px; }
    .content-table td:last-child {
      border-right: none;
      padding-right: 0; }

/*
.content-link-wrapper {
  display: block;
  margin-top: 8px;
  &:first-child {
    margin-top: 16px;
  }
}
*/
.content-link {
  text-decoration-line: underline;
  color: #007AFF; }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
