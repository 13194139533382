.header {
  background-color: #fff; }

.header-holder {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-width: 1058px) {
    .header-holder {
      height: 75px; } }
  @media only screen and (min-width: 1059px) {
    .header-holder {
      height: 91px; } }

.header-logo-icon {
  display: block;
  width: 148px;
  height: 43px; }

.header-nav-mob {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 999; }

.header-nav-holder {
  position: relative; }

.header-nav-open-icon {
  display: block;
  width: 24px;
  height: 24px; }

.header-nav-close-btn {
  display: block;
  position: absolute;
  right: 23px;
  top: 23px; }

.header-nav-close-icon {
  display: block;
  width: 14px;
  height: 14px; }

.header-nav-list {
  display: flex; }
  @media only screen and (max-width: 1058px) {
    .header-nav-list {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 120px; } }

@media only screen and (max-width: 1058px) {
  .header-nav-item:not(:first-child) {
    margin-top: 40px; } }

@media only screen and (min-width: 1059px) {
  .header-nav-item:not(:first-child) {
    margin-left: 32px; } }

.header-nav-link {
  font-weight: bold;
  color: #1B253D;
  transition: color .5s ease; }
  .header-nav-link:hover {
    color: #F0C602; }
  @media only screen and (max-width: 1058px) {
    .header-nav-link {
      font-size: 20px;
      line-height: 28px;
      text-align: center; } }
  @media only screen and (min-width: 1059px) {
    .header-nav-link {
      font-size: 16px;
      line-height: 24px; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
