@import "./variables.scss";

@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
  &::-webkit-text-fill-color { @content; }
}

@mixin triangle($width, $height, $color, $direction) {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  display: block;
  z-index: 2;
  // for a better anti-aliasing in webkit browser
  //+transform(rotate(360deg))

  @if $direction == right {
    border-top: $height/2 solid transparent;
    border-bottom: $height/2 solid transparent;
    border-left: $width solid $color;
    border-right: none;
  }

  @if $direction == left {
    border-top: $height/2 solid transparent;
    border-bottom: $height/2 solid transparent;
    border-right: $width solid $color;
    border-left: none;
  }

  @if $direction == up {
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
    border-bottom: $height solid $color;
    border-top: none;
  }

  @if $direction == down {
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
    border-top: $height solid $color;
    border-bottom: none;
  }
}

@mixin respond-to($media) {
    @if $media == handhelds {
        @media only screen and (max-width: $breakpoint - 1) { @content; } //984
    }
    @else if $media == wide-screens {
        @media only screen and (min-width: $breakpoint) { @content; } //985
    }
}

@mixin background-fix() { // background color fix
    @include respond-to(handhelds) {
        min-width: $holder_mobile; //375
    }
    @include respond-to(wide-screens) {
        min-width: $holder_desktop; //985
    }
}
//@include background-fix();


@mixin retina($pixel_ratio) {
    @if $pixel_ratio == x2 {
        @media
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 2dppx) {
            @content;
        }
    }
    @else if $pixel_ratio == x1 {
        @content;
    }
}
//@include retina(x2) {};

@mixin clearfix {
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
